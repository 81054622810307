<template>
  <ConfigurationEditor/>
</template>

<script>

import ConfigurationEditor from "@/components/ConfigurationEditor";

export default {
  name: 'Test2',
  components: {ConfigurationEditor},
}
</script>